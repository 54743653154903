import { CircularProgress } from '@material-ui/core';
import Wrapper from 'Components/Common/wrapper';
import { ProviderBestBidProps } from './props';

export default function ProviderBestBid(props: ProviderBestBidProps) {
    return (
        <Wrapper flex truncate textAlign='right' fontSize='13px'>
            {/* @ts-expect-error - `parseInt` expects a string argument but handles null or undefined */}
            {!Number.isNaN(parseInt(props.children)) ? (
                props.formattingFunction(props.children as string)
            ) : (
                <CircularProgress color='inherit' size={13} />
            )}
        </Wrapper>
    );
}
